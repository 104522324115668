import * as React from 'react';
import { Component } from 'react-simplified';
import { Card, Form, Row, Column, Button, Alert } from './widgets';
import { InputType, Tag } from './customTypes';
import tagServices from '../services/tagServices';
import questionServices from '../services/questionServices';
import userService from '../services/userServices';

import { history } from '../index';

export class CreateQuestion extends Component {
  input: InputType = {
    title: '',
    content: '',
    tags: [],
  };
  tags: Tag[] = [];
  search_tag_value = '';
  search_toggle = false;
  filtered_tags: Tag[] = [];
  authenticatedUser: boolean = false;
  render() {
    if (this.authenticatedUser) {
      //If user is logged in, show component
      return (
        <Card
          title={''}
          style={{ width: '100%', marginLeft: '0%', height: '100%', overflowY: 'scroll' }}
        >
          <h4 style={{ textAlign: 'center' }}>
            <b>Ask Question</b>
          </h4>
          <p>Title: </p>
          <Form.Input
            value={this.input.title}
            width={'100%'}
            type="text"
            onChange={(event) => (this.input.title = event.currentTarget.value)}
            placeholder="Be clear and concise as if you were asking another person"
          ></Form.Input>
          {/* Dynamically show max length */}
          <span style={{ float: 'right' }}>{this.input.title.length}/255</span>
          <br />
          <p>Question:</p>
          <Form.Textarea
            value={this.input.content}
            onChange={(event) => (this.input.content = event.currentTarget.value)}
            placeholder="Describe your problem and expand on the question in the title here"
            rows={10}
          ></Form.Textarea>
          <br />
          <p>Tags:</p>
          <Form.Input
            value={this.search_tag_value}
            onChange={(event) => {
              // Regex to sanitize input
              this.search_tag_value = event.currentTarget.value
                .toLowerCase()
                .replace(' ', '-')
                .replace(/[^a-zA-Z0-9-]/g, '');
              //filters tags dynamically
              this.filterTags();
            }}
            placeholder="Write to search for tags"
            type="string"
            width="100%"
            onFocus={() => {
              this.filterTags();
              this.search_toggle = true;
            }}
          ></Form.Input>
          {this.search_toggle && (
            // toggle that shows filtered tags
            <>
              <div className="scrollable-tag">
                {this.filtered_tags.map((tag, index) => (
                  <Row key={index}>
                    <Column>{tag.content} </Column>
                    <Column right>
                      <Button.Success
                        onClick={() => {
                          if (this.input.tags.length < 5) this.input.tags.push(tag);
                          else Alert.danger('Max amount of tags are 5');
                          this.search_tag_value = '';
                          this.filterTags();
                        }}
                      >
                        Add Tag
                      </Button.Success>
                    </Column>
                  </Row>
                ))}
                {this.filtered_tags.find((tag) => tag.content == this.search_tag_value) ||
                this.search_tag_value == '' ? (
                  ''
                ) : (
                  <Row>
                    <Column>{this.search_tag_value} </Column>
                    <Column right>
                      <Button.Success
                        onClick={() => {
                          this.input.tags.push({ content: this.search_tag_value });
                          this.search_tag_value = '';
                          this.filterTags();
                        }}
                      >
                        Create Tag
                      </Button.Success>
                    </Column>
                  </Row>
                )}
              </div>
            </>
          )}
          <p>
            {/* shows selected tags */}
            Current selected tag:{' '}
            {this.input.tags.length == 0
              ? 'None'
              : this.input.tags.map((tag, index) => (
                  <span
                    className="clean-hover-tag-delete"
                    // Removes tag as selected onClick
                    onClick={() => {
                      this.input.tags.splice(
                        this.input.tags.findIndex((find_tag) => find_tag.content == tag.content),
                        1,
                      );
                    }}
                    key={index}
                  >
                    {tag.content}
                  </span>
                ))}
          </p>
          <Button.Success onClick={this.postButton}>Post</Button.Success>{' '}
          <Button.Danger onClick={() => history.push('/questions')}>Cancel</Button.Danger>
        </Card>
      );
    } else {
      //If user is not logged in, show login prompt
      return (
        <div className="loginPrompt">
          <p>You need to log in to post questions</p>
        </div>
      );
    }
  }

  filterTags() {
    //Filters tags based on if tag includes search_tag_value
    this.filtered_tags = this.tags.filter(
      (tag) =>
        tag.content.toLowerCase().includes(this.search_tag_value.toLowerCase()) &&
        !this.input.tags.some((inputTag) => inputTag.content === tag.content),
    );
  }

  async postButton() {
    try {
      //Checking for invalid input
      if (this.input.title == '') throw 'A question needs a title';
      if (this.input.title.length >= 256) throw 'Title cannot be more than 255 characters';
      if (this.input.content == '') throw 'A question needs content';
      if (this.input.tags.length == 0) throw 'A question needs at least one Tag';

      await questionServices.createQuestion(this.input.title, this.input.content, this.input.tags);
      history.push('/questions');
    } catch (error) {
      Alert.danger(`Error creating question: ${error}`);
      console.error(error);
    }
  }

  async mounted() {
    try {
      const authenticated = await userService.authenticateUser();
      if (authenticated) this.authenticatedUser = true;
      else this.authenticatedUser = false;
      this.tags = await tagServices.getAll();
    } catch (error) {
      console.error(error);
    }
  }
}
