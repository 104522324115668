import axios from 'axios';
import { Answer, A_comment } from '../components/customTypes';

//needs to be the same as the same variable in server/src/services/server.ts
var baseUrl = '';
if (process.env.NODE_ENV == 'test') {
  baseUrl += 'http://localhost:3001/api/v1';
} else {
  // TypeScript cannot infer the types of environment variables, as they are
  // read at runtime and ts-ignore is therefore used
  //@ts-ignore
  baseUrl += IS_HTTPS ? 'https://' : 'http://';
  //@ts-ignore
  baseUrl += `${BASE_URL}:${PORT_NUMBER}/api/v1`;
}

axios.defaults.baseURL = baseUrl;

class AnswerServices {
  // Creates answer
  async createAnswer(question_id: number, content: string) {
    try {
      const response = await axios.post<{ answer_id: number }>('/answers/new/answer', {
        content,
        question_id,
      });
      return response.data.answer_id;
    } catch (error) {
      throw error;
    }
  }

  // Deletes answer
  async deleteAnswer(answer_id: number) {
    try {
      const response = await axios.delete<Answer>('/answers/delete/answer/' + answer_id);
      return response.data.answer_id;
    } catch (error) {
      throw error;
    }
  }

  // Updates answers
  async update(answer: Answer) {
    try {
      const response = await axios.put('/answers/update/answer', answer);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Upvotes answer
  async upvote(answer_id: number) {
    try {
      const response = await axios.put('/answers/upvote/' + answer_id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Downvotes answer
  async downvote(answer_id: number) {
    try {
      const response = await axios.put('/answers/downvote/' + answer_id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  //Adds new comment to answer
  async newComment(answer_id: number, content: string) {
    try {
      const response = await axios.post('/answers/new/comment', { content, answer_id });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  //Edits comment on answer
  async editComment(comment: A_comment) {
    try {
      const response = await axios.put('/answers/update/comment', { comment });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  //Deletes comment on answer
  async deleteComment(comment: A_comment) {
    try {
      const response = await axios.delete('/answers/delete/comment/' + comment.comment_id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

const answerServices = new AnswerServices();
export default answerServices;
