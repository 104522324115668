import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { Component } from 'react-simplified';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { createHashHistory } from 'history';
import { CreateQuestion } from './components/createQuestionComponent';
import { Home } from './components/homeComponent';
import { LoginPage } from './components/loginComponent';
import { CreateUser } from './components/createUserComponent';
import { Profile } from './components/profileComponent';
import { QuestionsInspect } from './components/questionInspectComponent';
import { Tags } from './components/tagComponent';
import { TopBar, SideBar } from './components/barComponents';
import { Alert } from './components/widgets';

//history is decleared here and exported to all files
export const history = createHashHistory();

//Pushes users that enters the website to correct path
const startUrl = '/questions';
if (history.location.pathname === '/') history.push(startUrl);

class MainComponent extends Component {
  render(): React.ReactNode {
    return <div id="mainComponentDiv">{this.props.children}</div>;
  }
}

let root = document.getElementById('root');
if (root)
  createRoot(root).render(
    <>
      <Alert />
      <HashRouter>
        <Switch>
          <Route
            exact
            path="/login"
            render={() => (
              <>
                <TopBar />
                <LoginPage />
              </>
            )}
          />
          <Route
            exact
            path="/createUser"
            render={() => (
              <>
                <TopBar />
                <CreateUser />
              </>
            )}
          />
          <Route>
            {/* Force rerender of topbar on path change */}
            <Route
              path="/"
              render={(props) => <TopBar key={props.location.pathname} {...props} />}
            />
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <SideBar></SideBar>
              <MainComponent>
                {/* Force rerender of home on path change to avoid no rerender when changing from /questions to /tags/:id */}
                <Switch>
                  <Route
                    exact
                    path="/questions"
                    render={(props) => <Home key={props.location.pathname} {...props} />}
                  />
                  <Route
                    exact
                    path="/tags/:id"
                    render={(props) => <Home key={props.location.pathname} {...props} />}
                  />
                  <Route exact path="/questions/create" component={CreateQuestion} />
                  <Route exact path="/questions/:id(\d+)" component={QuestionsInspect} />
                  <Route exact path="/profile/:username" component={Profile} />
                  <Route exact path="/tags" component={Tags} />
                </Switch>
              </MainComponent>
            </div>
          </Route>
        </Switch>
      </HashRouter>
    </>,
  );
