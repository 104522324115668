import * as React from 'react';
import { Component } from 'react-simplified';
import { upArrow, downArrow, Card, Form, Row, Column, Button, Alert } from './widgets';
import questionServices from '../services/questionServices';
import answerServices from '../services/answerServices';
import { A_comment, Answer, Q_comment, Q_or_A_Type, Question, Tag, User } from './customTypes';
import userService from '../services/userServices';
import { history } from '../index';
import { AxiosError } from 'axios';

export class QuestionsInspect extends Component<{ match: { params: { id: string } } }> {
  question: Question | null = null;
  answers: Answer[] = [];
  input = {
    toggle: false,
    update_toggle: false,
    type: '',
    content: '',
    title: '',
  };
  tags: Tag[] = [];
  user: User = { user_id: 0, username: '', email: '', picture: '' };
  q_or_a: Q_or_A_Type = { flag: '', id: 0, answer: null, question: null, comment: null };
  favorited_question: boolean = false;

  toggle_answer_ellipsis: boolean[] = [];
  toggle_question_ellipsis: boolean = true;
  toggle_c_question_ellipsis: boolean[] = [];
  toggle_c_answer_ellipsis: boolean[] = [];

  answerType: string = 'popular';

  render() {
    if (!this.question || !this.answers) return;
    else if (this.question) {
      return (
        <div style={{ width: '90%', marginLeft: '5%', marginTop: '5%' }}>
          <Card title="">
            {/* Card containt question */}
            <Row>
              <Column width={1}>
                {/* Upvotes and downvotes */}

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '50px',
                  }}
                >
                  {' '}
                  <p
                    onClick={() =>
                      this.question && this.upvoteQuestionButton(this.question.question_id)
                    }
                    className="nonSelectable vote-hover"
                    style={{ margin: '5px 0' }}
                  >
                    {' '}
                    {this.question.voted_by_user == 1 ? upArrow('#209852') : upArrow('grey')}
                  </p>
                  <p
                    style={{
                      margin: '5px 0',
                      width: '100%',
                      textAlign: 'center',
                      userSelect: 'none',
                    }}
                  >
                    {this.question.upvotes}
                  </p>
                  <p
                    onClick={() =>
                      this.question && this.downvoteQuestionButton(this.question.question_id)
                    }
                    className="nonSelectable vote-hover"
                    style={{ margin: '5px 0' }}
                  >
                    {' '}
                    {this.question.voted_by_user == 0 ? downArrow('#ed3e41') : downArrow('grey')}
                  </p>
                </div>
              </Column>
              <Column matchParent width={11}>
                <Row max_width>
                  {/* Question top info */}
                  <Column>
                    <span
                      onClick={() => {
                        if (this.question?.username != '[deleted]')
                          history.push(`/profile/${this.question?.username}`);
                      }}
                      className={
                        this.question?.username == '[deleted]' ? '' : 'top-text nonSelectable'
                      }
                    >
                      {this.question.username}{' '}
                      {this.user.user_id == this.question.user_id && '(You)'}
                    </span>
                    <span style={{ marginLeft: '30px' }} className="top-text">
                      {this.displayTime(this.question.timestamp)}
                    </span>
                  </Column>

                  <Column right>
                    {/* Show ellipsis if user is logged in */}
                    {this.user.user_id != 0 &&
                      (this.toggle_question_ellipsis ? (
                        <span
                          onClick={() => (this.toggle_question_ellipsis = false)}
                          className="nonSelectable"
                        >
                          ...
                        </span>
                      ) : (
                        <span
                          onMouseLeave={() => {
                            this.toggle_question_ellipsis = true;
                          }}
                        >
                          {/* Content of ellipsis is different based on credentials */}
                          {/* Show edit and delete buttons if it is users own question */}
                          {this.question.user_id === this.user.user_id && (
                            <>
                              <Button.Light
                                onClick={() => {
                                  if (this.question) {
                                    this.input.update_toggle = true;
                                    this.input.title = this.question.title;
                                    this.input.content = this.question.content;
                                    this.q_or_a.flag = 'q';
                                    this.q_or_a.id = this.question.question_id;
                                    this.q_or_a.question = this.question;
                                  }
                                }}
                              >
                                Edit
                              </Button.Light>{' '}
                              <Button.Danger
                                onClick={() =>
                                  this.question && this.deletePost('q', this.question.question_id)
                                }
                              >
                                Delete
                              </Button.Danger>
                            </>
                          )}
                          {/* Show favorite question toggle for all users */}
                          {this.favorited_question ? (
                            <Button.Light onClick={() => this.unsaveQuestion()}>
                              Unsave Question
                            </Button.Light>
                          ) : (
                            <Button.Light onClick={() => this.saveQuestion()}>
                              Save question
                            </Button.Light>
                          )}
                        </span>
                      ))}
                  </Column>
                </Row>
                <Row>
                  {/* Question content */}
                  <Column matchParent>
                    <h5>
                      <b>{this.question.title}</b>
                    </h5>
                    <p>
                      {this.question.content.split('\n').map((line, index) => (
                        <span key={index}>
                          {line}
                          <br />
                        </span>
                      ))}
                    </p>
                    <p>
                      {this.question.tags?.map((tag: Tag, index) => (
                        <span
                          className="clean-hover-tag nonSelectable"
                          key={index}
                          onClick={() => history.push(`/tags/${tag.content}`)}
                        >
                          #{tag.content}{' '}
                        </span>
                      ))}
                    </p>
                    <br />
                    <div>
                      <Button.Light
                        onClick={() => {
                          if (this.question) {
                            this.input.toggle = true;
                            this.input.type = 'Comment';
                            this.q_or_a.flag = 'q';
                            this.q_or_a.id = this.question?.question_id;
                          }
                        }}
                      >
                        Comment
                      </Button.Light>{' '}
                      <Button.Light
                        onClick={() => {
                          if (this.question) {
                            this.input.toggle = true;
                            this.input.type = 'Answer';
                            this.q_or_a.id = this.question?.question_id;
                          }
                        }}
                      >
                        Answer
                      </Button.Light>
                    </div>
                    <br></br>
                  </Column>
                </Row>
              </Column>
            </Row>
            {this.question.comments?.length != 0 && (
              // Question comments
              <Card title="Comments:">
                {this.question.comments?.map((comment) => (
                  <div key={comment.comment_id}>
                    {<hr></hr>}
                    <Row>
                      <Column>
                        <span
                          onClick={() => {
                            if (comment.username != '[deleted]')
                              history.push(`/profile/${comment.username}`);
                          }}
                          className={
                            comment.username == '[deleted]' ? '' : 'top-text nonSelectable'
                          }
                        >
                          {comment.username} {this.user.user_id == comment.user_id && '(You)'}
                        </span>
                        <span style={{ marginLeft: '30px' }} className="top-text">
                          {this.displayTime(comment.timestamp)}
                        </span>
                      </Column>
                      <Column right>
                        {this.user &&
                          this.user.user_id == comment.user_id &&
                          (this.toggle_c_question_ellipsis[comment.comment_id] ? (
                            <span
                              onClick={() =>
                                (this.toggle_c_question_ellipsis[comment.comment_id] = false)
                              }
                              className="nonSelectable"
                            >
                              ...
                            </span>
                          ) : (
                            <span
                              onMouseLeave={() => {
                                this.toggle_c_question_ellipsis[comment.comment_id] = true;
                              }}
                            >
                              <Button.Light
                                onClick={() => {
                                  if (this.question) {
                                    this.input.update_toggle = true;
                                    this.input.content = comment.content;
                                    this.q_or_a.flag = 'q';
                                    this.q_or_a.id = comment.question_id;
                                    this.q_or_a.comment = comment;
                                  }
                                }}
                              >
                                Edit
                              </Button.Light>{' '}
                              <Button.Danger onClick={() => this.deleteQComment(comment)}>
                                Delete
                              </Button.Danger>
                            </span>
                          ))}
                      </Column>
                    </Row>
                    <Row>
                      <Column matchParent>
                        {comment.content.split('\n').map((line: string, index: number) => (
                          <span key={index}>
                            {line}
                            <br />
                          </span>
                        ))}
                      </Column>
                    </Row>
                  </div>
                ))}
              </Card>
            )}
          </Card>
          <br></br>
          <Row>
            {/* Sort answers */}
            <Column width={1}>
              <div
                className={`clean-hover ${this.answerType === 'recent' ? 'active' : ''}`}
                onClick={() => {
                  this.answerType = 'recent';
                  this.sortAnswers();
                }}
              >
                Recent
              </div>
            </Column>
            <Column width={1}>
              <div
                className={`clean-hover ${this.answerType === 'popular' ? 'active' : ''}`}
                onClick={() => {
                  this.answerType = 'popular';
                  this.sortAnswers();
                }}
              >
                Popular
              </div>
            </Column>
          </Row>
          <br></br>
          <Card title="Answers:">
            {/* Card that shows all answers */}
            {this.answers.map((answer, index) => (
              <div key={index}>
                <hr></hr>
                <Row>
                  {/* Upvote and downvote button for each answer */}
                  <Column width={1}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '50px',
                      }}
                    >
                      {' '}
                      <p
                        onClick={() => this.upvoteAnswerButton(answer.answer_id)}
                        className="nonSelectable vote-hover"
                        style={{ margin: '5px 0' }}
                      >
                        {answer.voted_by_user == 1 ? upArrow('#209852') : upArrow('grey')}
                      </p>
                      <p
                        style={{
                          margin: '5px 0',
                          width: '100%',
                          textAlign: 'center',
                          userSelect: 'none',
                        }}
                      >
                        {' '}
                        {answer.upvotes}
                      </p>
                      <p
                        onClick={() => this.downvoteAnswerButton(answer.answer_id)}
                        className="nonSelectable vote-hover"
                        style={{ margin: '5px 0' }}
                      >
                        {answer.voted_by_user == 0 ? downArrow('#ed3e41') : downArrow('grey')}
                      </p>
                    </div>
                  </Column>
                  <Column matchParent width={11}>
                    <Row>
                      <Column>
                        {answer.best_answer && (
                          // If answer is marked as best answer, render this:
                          <>
                            <span className="green-glow-text">
                              {' '}
                              Marked by author as best answer
                            </span>{' '}
                            <svg
                              width="28"
                              height="28"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 28 28"
                            >
                              <circle cx="14" cy="14" r="12.5" fill="#7ac142" />
                              <path
                                fill="none"
                                stroke="#fff"
                                strokeWidth="2"
                                d="M7.5 14l3.5 3.5 8.5-8.5"
                              />
                            </svg>
                          </>
                        )}
                      </Column>
                    </Row>
                    <Row max_width>
                      {/* Answer top row */}
                      <Column width={7}>
                        <span
                          className={answer.username == '[deleted]' ? '' : 'top-text nonSelectable'}
                          onClick={() => {
                            if (answer.username != '[deleted]')
                              history.push(`/profile/${answer.username}`);
                          }}
                        >
                          {answer.username} {this.user.user_id == answer.user_id && '(You)'}
                        </span>
                        <span style={{ marginLeft: '30px' }} className="top-text">
                          {this.displayTime(answer.timestamp)}
                        </span>
                      </Column>
                      <Column right>
                        {/* answer ellipsis */}
                        <span style={{ marginLeft: '30px' }}>
                          {(this.user.user_id === answer.user_id ||
                            this.user.user_id === this.question?.user_id) &&
                            (!this.toggle_answer_ellipsis[answer.answer_id] ? (
                              <span
                                onMouseLeave={() => {
                                  this.toggle_answer_ellipsis[answer.answer_id] = true;
                                }}
                              >
                                <>
                                  {this.user &&
                                    this.question &&
                                    this.user.user_id === this.question.user_id &&
                                    (answer.best_answer ? (
                                      <Button.Light
                                        onClick={() => {
                                          this.removePinAnswerButton(answer);
                                        }}
                                      >
                                        Remove as best answer
                                      </Button.Light>
                                    ) : (
                                      <Button.Light
                                        onClick={() => {
                                          this.pinAnswerButton(answer);
                                        }}
                                      >
                                        Pin as best answer
                                      </Button.Light>
                                    ))}{' '}
                                  {this.user && this.user.user_id === answer.user_id && (
                                    <>
                                      <Button.Light
                                        onClick={() => {
                                          if (this.question) {
                                            this.q_or_a.flag = 'a';
                                            this.q_or_a.id = answer.answer_id;
                                            this.q_or_a.answer = answer;
                                            this.input.update_toggle = true;
                                            this.input.content = answer.content;
                                          }
                                        }}
                                      >
                                        Edit
                                      </Button.Light>{' '}
                                      <Button.Danger
                                        onClick={() =>
                                          this.question && this.deletePost('a', answer.answer_id)
                                        }
                                      >
                                        Delete
                                      </Button.Danger>
                                    </>
                                  )}
                                </>
                              </span>
                            ) : (
                              <span
                                onClick={() =>
                                  (this.toggle_answer_ellipsis[answer.answer_id] = false)
                                }
                                className="nonSelectable"
                              >
                                ...
                              </span>
                            ))}
                        </span>
                      </Column>
                    </Row>
                    <Row>
                      <Column matchParent>
                        <br></br>
                        <p>
                          {answer.content.split('\n').map((line: string, index: number) => (
                            <span key={index}>
                              {line}
                              <br />
                            </span>
                          ))}
                        </p>
                        <Button.Light
                          onClick={() => {
                            this.input.toggle = true;
                            this.input.type = 'Comment';
                            this.q_or_a.flag = 'a';
                            this.q_or_a.id = answer.answer_id;
                          }}
                        >
                          Comment
                        </Button.Light>
                        <br></br>
                      </Column>
                    </Row>
                  </Column>

                  <br></br>
                  <br></br>
                </Row>
                {answer.comments.length != 0 && (
                  <>
                    {/* Comments on answers */}
                    <br></br>
                    <Card title="Comments:">
                      {answer.comments.map((comment) => (
                        <div key={comment.comment_id}>
                          {<hr></hr>}
                          <Row>
                            <Column>
                              <Column>
                                <span
                                  onClick={() => {
                                    if (comment.username != '[deleted]')
                                      history.push(`/profile/${comment.username}`);
                                  }}
                                  className={
                                    comment.username == '[deleted]' ? '' : 'top-text nonSelectable'
                                  }
                                >
                                  {comment.username}{' '}
                                  {this.user.user_id == comment.user_id && '(You)'}
                                </span>
                                <span style={{ marginLeft: '30px' }} className="top-text">
                                  {this.displayTime(comment.timestamp)}
                                </span>
                              </Column>
                            </Column>
                            <Column right>
                              {this.user &&
                                this.user.user_id == comment.user_id &&
                                (this.toggle_c_answer_ellipsis[comment.comment_id] ? (
                                  <span
                                    onClick={() => {
                                      this.toggle_c_answer_ellipsis[comment.comment_id] = false;
                                    }}
                                    className="nonSelectable"
                                  >
                                    ...
                                  </span>
                                ) : (
                                  <span
                                    onMouseLeave={() => {
                                      this.toggle_c_answer_ellipsis[comment.comment_id] = true;
                                    }}
                                  >
                                    <Button.Light
                                      onClick={() => {
                                        if (this.question) {
                                          this.input.update_toggle = true;
                                          this.input.content = comment.content;
                                          this.q_or_a.flag = 'a';
                                          this.q_or_a.id = comment.answer_id;
                                          this.q_or_a.comment = comment;
                                        }
                                      }}
                                    >
                                      Edit
                                    </Button.Light>{' '}
                                    <Button.Danger onClick={() => this.deleteAComment(comment)}>
                                      Delete
                                    </Button.Danger>
                                  </span>
                                ))}
                            </Column>
                          </Row>
                          <Row>
                            <Column matchParent>
                              {' '}
                              {comment.content.split('\n').map((line: string, index: number) => (
                                <span key={index}>
                                  {line}
                                  <br />
                                </span>
                              ))}
                            </Column>
                          </Row>
                        </div>
                      ))}
                    </Card>
                  </>
                )}
              </div>
            ))}

            {this.input.toggle && (
              // pops up every time a user clicks comment or answer button
              <div id="replyPopUpDiv">
                <h3>Enter your {this.input.type}</h3>
                Content:
                <Form.Textarea
                  onChange={(event) => (this.input.content = event.currentTarget.value)}
                  value={this.input.content}
                  rows={10}
                ></Form.Textarea>
                <div style={{ textAlign: 'right' }}>{this.input.content.length} Characters </div>
                <Button.Success
                  onClick={() => {
                    this.addReply();
                  }}
                >
                  Submit
                </Button.Success>
                <Button.Danger
                  onClick={() => {
                    this.input.toggle = false;
                    this.input.content = '';
                  }}
                >
                  Cancel
                </Button.Danger>
              </div>
            )}
            {this.input.update_toggle && (
              // pops up every time a user clicks edit button
              <div id="replyPopUpDiv">
                <h3>Editing</h3>
                {this.q_or_a.question && (
                  <>
                    <span>Title:</span>
                    <Form.Textarea
                      onChange={(event) => (this.input.title = event.currentTarget.value)}
                      value={this.input.title}
                      rows={2}
                    ></Form.Textarea>
                    <div style={{ textAlign: 'right' }}>{this.input.title.length}/255 </div>
                  </>
                )}
                Content:
                <Form.Textarea
                  onChange={(event) => (this.input.content = event.currentTarget.value)}
                  value={this.input.content}
                  rows={10}
                ></Form.Textarea>
                <div style={{ textAlign: 'right' }}>{this.input.content.length} Characters </div>
                <Button.Success
                  onClick={() => {
                    this.updateQAC();
                  }}
                >
                  Submit
                </Button.Success>
                <Button.Danger
                  onClick={() => {
                    this.input.update_toggle = false;
                    this.input.content = '';
                  }}
                >
                  Cancel
                </Button.Danger>
              </div>
            )}
          </Card>
        </div>
      );
    }
  }

  sortAnswers() {
    //Sort answers to most recent answers first
    if (this.answerType == 'recent') {
      this.answers.sort(
        (a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
      );
    }
    //Sort answers to most popular answers first
    if (this.answerType == 'popular') {
      this.answers.sort((a, b) => b.upvotes - a.upvotes);
    }

    //makes sure pinned answer is always first
    this.answers.sort((a, b) => {
      if (a.best_answer && !b.best_answer) {
        return -1;
      }
      if (!a.best_answer && b.best_answer) {
        return 1;
      }
      return 0;
    });
  }

  async unsaveQuestion() {
    try {
      if (this.question) await questionServices.unsaveQuestion(this.question);
      this.mounted();
    } catch (error) {
      console.error(error);
    }
  }
  async saveQuestion() {
    try {
      if (this.question) await questionServices.saveQuestion(this.question);
      this.mounted();
    } catch (error) {
      console.error(error);
    }
  }

  async pinAnswerButton(answer: Answer) {
    try {
      await questionServices.selectBestAnswer(answer);
      this.mounted();
    } catch (error) {
      console.error(error);
    }
  }

  async removePinAnswerButton(answer: Answer) {
    try {
      await questionServices.unselectBestAnswer(answer);
      this.mounted();
    } catch (error) {
      console.error(error);
    }
  }

  async upvoteQuestionButton(answer_id: number) {
    try {
      await questionServices.upvote(answer_id);
      this.mounted();
    } catch (error) {
      console.error(error);
    }
  }
  async downvoteQuestionButton(answer_id: number) {
    try {
      await questionServices.downvote(answer_id);
      this.mounted();
    } catch (error) {
      console.error(error);
    }
  }

  async upvoteAnswerButton(answer_id: number) {
    try {
      await answerServices.upvote(answer_id);
      this.mounted();
    } catch (error) {
      console.error(error);
    }
  }
  async downvoteAnswerButton(answer_id: number) {
    try {
      await answerServices.downvote(answer_id);
      this.mounted();
    } catch (error) {
      console.error(error);
    }
  }

  displayTime(timestamp: string) {
    const date = new Date(timestamp);
    let minutes = date.getMinutes().toString();
    minutes = minutes.length < 2 ? '0' + minutes : minutes;
    return `${date.getHours()}:${minutes} ${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
  }

  async addReply() {
    try {
      //throw error if user is not logged in
      if (!(await userService.authenticateUser())) {
        console.log('problem');
        throw 'You need to be logged in to post comments/answers';
      }
      //throw error if input is empty
      if (this.input.content == '') throw 'content cant be empty';

      if (this.input.type == 'Answer') {
        await answerServices.createAnswer(this.q_or_a.id, this.input.content);
      }

      //check if comment input is valid
      if (this.input.type == 'Comment' && this.input.content.length > 500) {
        throw 'Comment cannot exceed 500 characters';
      }

      if (this.input.type == 'Comment') {
        if (this.q_or_a.flag == 'q') {
          await questionServices.newComment(this.q_or_a.id, this.input.content);
        }
        if (this.q_or_a.flag == 'a') {
          await answerServices.newComment(this.q_or_a.id, this.input.content);
        }
      }

      //removes popup div
      this.input.toggle = false;
      this.input.content = '';
      this.mounted();
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) {
        Alert.danger(`Error creating content: ${error.response.data.message}`);
      } else {
        Alert.danger(`Error creating content: ${error}`);
      }
    }
  }

  async deleteQComment(comment: Q_comment) {
    try {
      await questionServices.deleteComment(comment);
      this.mounted();
    } catch (error) {
      console.error(error);
    }
  }

  async deleteAComment(comment: A_comment) {
    try {
      await answerServices.deleteComment(comment);
      this.mounted();
    } catch (error) {
      console.error(error);
    }
  }

  async deletePost(q_or_a: string, id: number) {
    try {
      if (q_or_a == 'q') await questionServices.deleteQuestion(id);
      if (q_or_a == 'a') await answerServices.deleteAnswer(id);
      this.mounted();
    } catch (error) {
      console.error(error);
    }
  }

  async updateQAC() {
    try {
      if ((this.q_or_a.flag === 'q' || this.q_or_a.flag === 'a') && this.q_or_a.comment) {
        if (this.input.content.length > 500) {
          throw 'Comment cannot exceed 500 characters';
        }
      }
      if (this.q_or_a.flag == 'q' && this.input.title.length > 255) {
        throw 'Title cannot exceed 255 characters';
      }
      //if flag i q, the edit is in relation to a question
      if (this.q_or_a.flag == 'q') {
        if (this.q_or_a.question) {
          //if question is defined the edit is to a question
          if (this.input.title == '' || this.input.content == '')
            throw 'Title and content cant be empty';
          this.q_or_a.question.title = this.input.title;
          this.q_or_a.question.content = this.input.content;
          await questionServices.update(this.q_or_a.question);
        } else if (this.q_or_a.comment) {
          //if comment is defined the edit is to a comment on a question
          if (this.input.content == '') throw 'Content cant be empty';
          this.q_or_a.comment.content = this.input.content;

          await questionServices.editComment(this.q_or_a.comment as Q_comment);
        }
        //if flag i a, the edit is in relation to an answer
      } else if (this.q_or_a.flag == 'a') {
        if (this.q_or_a.answer) {
          //if answer is defined the edit is to an answer
          if (this.input.content == '') throw 'Content cant be empty';
          this.q_or_a.answer.content = this.input.content;
          await answerServices.update(this.q_or_a.answer);
        } else if (this.q_or_a.comment) {
          //if comment is defined the edit is to a comment on an answer
          if (this.input.content == '') throw 'Content cant be empty';
          this.q_or_a.comment.content = this.input.content;
          await answerServices.editComment(this.q_or_a.comment as A_comment);
        }
      }

      //removes popup div
      this.input.update_toggle = false;
      this.input.content = '';
      this.input.title = '';
      //resets q_or_a to make sure that values are not carried forward to next edit
      this.q_or_a = { flag: '', id: 0, answer: null, question: null, comment: null };

      this.mounted();
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) {
        Alert.danger(`Error updating: ${error.response.data.message}`);
      } else Alert.danger(`Error updating: ${error}`);
    }
  }

  sortEllipsises() {
    //for this.answers this.questions.comments and for all comments on each answer, an array filled with boolean values are needed
    //to keep track of whether the ellipsis is toggled or not.

    this.answers.forEach((answer) => (this.toggle_answer_ellipsis[answer.answer_id] = true));

    this.question?.comments?.forEach(
      (comment) => (this.toggle_c_question_ellipsis[comment.comment_id] = true),
    );

    //All comment ids are distinct meaning we dont need a separate array for each answer
    this.answers.forEach((answer) => {
      answer.comments?.forEach(
        (comment) => (this.toggle_c_answer_ellipsis[comment.comment_id] = true),
      );
    });
  }

  async mounted() {
    try {
      const response = await questionServices.getQuestion(Number(this.props.match.params.id));
      this.question = response;

      this.answers = response.answers;
      this.sortAnswers();
      this.sortEllipsises();
      if (await userService.authenticateUser()) {
        this.user = await userService.getUser();

        //Checks if question has been saved by user
        const savedQuestions: Question[] = await questionServices.getSavedQuestions();
        this.favorited_question = savedQuestions.find(
          (question) => question.question_id == this.question?.question_id,
        )
          ? true
          : false;
      }
    } catch (error) {
      console.error(error);
    }
  }
}
