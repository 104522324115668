import * as React from 'react';
import { Component } from 'react-simplified';
import { Card, Form, Button, Alert } from './widgets';
import userService from '../services/userServices';
import { AxiosError } from 'axios';
import { history } from '../index';

export class CreateUser extends Component {
  username: string = '';
  firstName: string = '';
  lastName: string = '';
  email: string = '';
  password: string = '';
  confirmPassword: string = '';
  docker = false;

  render() {
    return (
      <Card id="createUserCard">
        <h1>Registration Page</h1>
        <b>Username</b>
        <Form.Input
          type=""
          value={this.username}
          onChange={(event) => {
            //Regex to sanitize input
            this.username = event.currentTarget.value
              .replace(/[^a-zA-Z0-9-_]/g, '')
              .replace(/\u200B/g, '');
          }}
        ></Form.Input>
        <b>First name</b>
        <Form.Input
          type=""
          value={this.firstName}
          onChange={(event) => (this.firstName = event.currentTarget.value)}
        ></Form.Input>
        <b>Last name</b>
        <Form.Input
          type=""
          value={this.lastName}
          onChange={(event) => (this.lastName = event.currentTarget.value)}
        ></Form.Input>
        <b>Email</b>
        <Form.Input
          type="email"
          value={this.email}
          onChange={(event) => {
            //Regex to sanitize input
            this.email = event.currentTarget.value
              .replace(/[^a-zA-Z0-9!#$%&*+\-_~@.]+/g, '')
              .replace(/\u200B/g, '');
          }}
        ></Form.Input>
        <b>Password</b>
        <Form.Input
          type="Password"
          value={this.password}
          onChange={(event) => (this.password = event.currentTarget.value)}
        ></Form.Input>
        <b>Confirm your password</b>
        <Form.Input
          type="Password"
          value={this.confirmPassword}
          onChange={(event) => (this.confirmPassword = event.currentTarget.value)}
        ></Form.Input>
        <br />
        <Button.Success onClick={() => this.registrationButton()}>Register</Button.Success>
        <br />
        <br />
        Already registered?
        <br />
        <u
          //link to login page
          style={{ color: 'blue' }}
          onClick={() => history.push('/login')}
          className="nonSelectable"
        >
          {' '}
          Login here{' '}
        </u>
        <br></br>
        {/* link to google authentication */}
        {!this.docker && (
          <a className="google-login" href="/auth/google">
            Sign in with Google
          </a>
        )}
      </Card>
    );
  }

  async registrationButton() {
    //Checking if input is valid
    if (this.password != this.confirmPassword) {
      Alert.danger('Password and password confirmation are not the same');
      return;
    }

    if (this.username.length < 2) {
      Alert.danger('Username too short. Min 2 characters');
      return;
    }

    if (this.username.length > 32) {
      Alert.danger('Username too long. Max 32 characters');
      return;
    }

    if (
      this.password.length < 1 ||
      this.firstName.length < 1 ||
      this.lastName.length < 1 ||
      this.email.length < 1
    ) {
      Alert.danger('Please fill out every field');
      return;
    }
    try {
      await userService.create(
        this.username,
        this.password,
        this.email,
        this.firstName,
        this.lastName,
      );

      await userService.login(this.username, this.password);

      history.push('/questions');
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response)
        Alert.danger(`Error logging in: ${error.response.data.message}`);
      else Alert.danger(`Error logging in: Unkown error`);
      console.error(error);
    }
  }

  mounted() {
    if (process.env.NODE_ENV != 'test') {
      //@ts-ignore
      if (DOCKER) {
        this.docker = true;
      }
    }
  }
}
