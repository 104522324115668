import * as React from 'react';
import { Component } from 'react-simplified';
import { Card, Form, Row, Column, Button, minusSVG, plusSVG } from '../components/widgets';
import tagServices from '../services/tagServices';
import { Question, Tag, User } from './customTypes';
import userService from '../services/userServices';
import questionServices from '../services/questionServices';
import { history } from '../index';

export class SideBar extends Component {
  favorites: Tag[] = [];
  popular: Tag[] = [];

  favoriteToggle: boolean = false;
  popularToggle: boolean = false;

  userAuthenticated: boolean = false;

  render() {
    return (
      <Card id="sideBarCard">
        {/* Link to tags */}
        <h3
          //conditional style based on pathname
          className={`clean-hover ${history.location.pathname === '/tags' ? 'active' : ''}`}
          style={{ textAlign: 'center' }}
          onClick={() => {
            if (history.location.pathname != '/tags') history.push('/tags');
            this.mounted();
          }}
        >
          Tags
        </h3>
        {/* Toggles rendering of favorite tags onClick */}
        <div>
          <h5
            onClick={() => {
              this.popularToggle = false;
              this.favoriteToggle = !this.favoriteToggle;
            }}
            className="clean-hover-tag"
          >
            Favorites {this.favoriteToggle ? minusSVG : plusSVG}
          </h5>
          <br></br>
          {this.favoriteToggle &&
            (this.userAuthenticated ? (
              // If user is logged in, show favorite tags if they have any
              this.favorites.length > 0 ? (
                this.favorites.map((fav, index) => (
                  <div key={index} className="tag-item">
                    <p
                      //conditional style based on pathname
                      className={`clean-hover ${
                        history.location.pathname === `/tags/${fav.content}` ? 'active' : ''
                      }`}
                      style={{ marginLeft: '0vw' }}
                      onClick={() => {
                        if (history.location.pathname != `/tags/${fav.content}`) {
                          history.push(`/tags/${fav.content}`);
                          this.mounted();
                        }
                      }}
                    >
                      {fav.content}
                    </p>
                  </div>
                ))
              ) : (
                <div key={'no_favorites'} className="tag-item">
                  <p>You haven't saved any favorite tags yet!</p>
                </div>
              )
            ) : (
              // If user is not logged in, tell user to logg in
              <p
                className="nonSelectable"
                style={{ marginLeft: '0vw', color: 'blue', textDecoration: 'underline' }}
                onClick={() => {
                  if (history.location.pathname != '/login') history.push(`/login`);
                }}
              >
                Log in to see your favorite tags{' '}
              </p>
            ))}
        </div>
        {/* Toggles rendering of popular tags onClick */}
        <div>
          <h5
            className="clean-hover-tag"
            onClick={() => {
              this.popularToggle = !this.popularToggle;
              this.favoriteToggle = false;
            }}
          >
            Popular {this.popularToggle ? minusSVG : plusSVG}
          </h5>
          <br></br>

          {this.popularToggle &&
            this.popular.map((pop, index) => (
              <div key={index} className="tag-item">
                <p
                  //conditional style based on pathname
                  className={`clean-hover ${
                    history.location.pathname === `/tags/${pop.content}` ? 'active' : ''
                  }`}
                  style={{ marginLeft: '0vw' }}
                  onClick={() => {
                    if (history.location.pathname != `/tags/${pop.content}`) {
                      history.push(`/tags/${pop.content}`);
                      this.mounted();
                    }
                  }}
                >
                  {pop.content}
                </p>
              </div>
            ))}
        </div>
      </Card>
    );
  }

  async mounted() {
    //rerender component to force style changes
    this.forceUpdate();

    try {
      this.popular = await tagServices.getPopular();
      if (await userService.authenticateUser()) {
        this.userAuthenticated = true;
        this.favorites = await tagServices.getFavorites();
      } else this.userAuthenticated = false;
    } catch (error) {
      console.error(error);
    }
  }
}

export class TopBar extends Component {
  user: User | null = null;
  //keeps track of if user has been loaded to avoid flickering
  user_loaded: boolean = false;

  search_toggle: boolean = false;
  blurTimeout: ReturnType<typeof setTimeout> | null = null;

  all: { tags: Tag[]; users: { username: string }[]; questions: Question[] } = {
    tags: [],
    users: [],
    questions: [],
  };

  search_value = '';
  filters: { tags: Tag[]; users: { username: string }[]; questions: Question[] } = {
    tags: [],
    users: [],
    questions: [],
  };

  render() {
    return (
      <>
        <Card>
          <Row>
            <Column width={1}>
              {/* Logo that links to homepage */}
              <img
                className="nonSelectable vote-hover"
                src="logo.jpg"
                id="topBarLogo"
                alt="Logo Description"
                onClick={() => {
                  if (history.location.pathname != '/questions') {
                    history.push('/questions');
                    //Force rerender of sidebar to change active styles
                    SideBar.instance()?.mounted();
                  }
                }}
              />
            </Column>
            {/* Link to Ask Question */}
            <Column width={2}>
              <span
                onClick={() => {
                  if (history.location.pathname != '/questions/create') {
                    history.push('/questions/create');
                    SideBar.instance()?.mounted();
                  }
                }}
                className={`clean-hover ${
                  history.location.pathname == '/questions/create' ? 'active' : ''
                }`}
              >
                Ask Question
              </span>
            </Column>
            {/* Search bar that toggles a static div onFocus */}
            <Column>
              <Form.Input
                onFocus={() => {
                  //Filters content based on previous input
                  this.filterContent();
                  this.search_toggle = true;
                  if (this.blurTimeout) clearTimeout(this.blurTimeout);
                }}
                // OnBlur untoggles static div
                onBlur={() => {
                  // small timeout to make sure that onclick is triggered before onblur
                  this.blurTimeout = setTimeout(() => {
                    this.search_toggle = false;
                  }, 150);
                }}
                type="string"
                onChange={(event) => {
                  this.search_value = event.currentTarget.value;
                  //Filters content dynamically based on user input
                  this.filterContent();
                }}
                value={this.search_value}
                placeholder="Search"
                width={'50vw'}
              ></Form.Input>
            </Column>
            <Column width={1} className="column-class-for-create-user">
              {/* Link to create user if user not logged in  and user is loaded*/}
              {this.user_loaded && !this.user && (
                <span
                  onClick={() => {
                    if (history.location.pathname != '/createUser') {
                      history.push('/createUser');
                      SideBar.instance()?.mounted();
                    }
                  }}
                  className={`clean-hover ${
                    history.location.pathname === '/createUser' ? 'active' : ''
                  }`}
                >
                  Create User
                </span>
              )}
            </Column>
            <Column width={1}>
              {this.user_loaded &&
                (this.user ? (
                  // Link to log in if user not logged in and user is loaded
                  <span
                    onClick={() => {
                      if (history.location.pathname != `/profile/${this.user?.username}`) {
                        history.push(`/profile/${this.user?.username}`);
                        SideBar.instance()?.mounted();
                      }
                    }}
                    className={`clean-hover ${
                      history.location.pathname === `/profile/${this.user?.username}`
                        ? 'active'
                        : ''
                    }`}
                  >
                    Profile
                  </span>
                ) : (
                  // Link to profile if user not logged in
                  <span
                    onClick={() => {
                      if (history.location.pathname != '/login') {
                        history.push('/login');
                        SideBar.instance()?.mounted();
                      }
                    }}
                    className={`clean-hover ${
                      history.location.pathname === '/login' ? 'active' : ''
                    }`}
                  >
                    Login
                  </span>
                ))}
            </Column>
          </Row>
        </Card>
        {/* Togglable static div that shows filtered content based on user search input */}
        {this.search_toggle && (
          <div id="searchDropDownDiv">
            <p>
              <span>Search for tags with #</span>
              <br></br>
              <span>Search for users with $</span>
            </p>
            {/* Renders different content based on leading character to optimize searching  */}
            <div className="scrollable-search-tag">
              {/* Tags are searchable by # as leading character */}
              {this.search_value[0] == '#' &&
                this.filters.tags.map((tag, index) => (
                  <Row key={index}>
                    <Column>{tag.content} </Column>
                    <Column right>
                      <Button.Success
                        onClick={() => {
                          if (history.location.pathname != `/tags/${tag.content}`) {
                            history.push(`/tags/${tag.content}`);
                            SideBar.instance()?.mounted();
                          }
                          //Reset search bar
                          this.search_value = '';
                        }}
                      >
                        Search for tag
                      </Button.Success>
                    </Column>
                  </Row>
                ))}
              {/* Users are searchable by $ as leading character */}
              {this.search_value[0] == '$' &&
                this.filters.users.map((user, index) => (
                  <Row key={index}>
                    <Column>{user.username} </Column>
                    <Column right>
                      <Button.Success
                        onClick={() => {
                          if (history.location.pathname != `/profile/${user.username}`) {
                            history.push(`/profile/${user.username}`);
                            SideBar.instance()?.mounted();
                          }
                          this.search_value = '';
                          this.filterContent();
                        }}
                      >
                        Search for user
                      </Button.Success>
                    </Column>
                  </Row>
                ))}
              {/* Questions are searchable by no special leading character */}
              {this.search_value[0] != '$' &&
                this.search_value[0] != '$' &&
                this.search_value != '' &&
                this.filters.questions.map((question) => (
                  <Row key={question.question_id}>
                    <Column>{question.title} </Column>
                    <Column right>
                      <Button.Success
                        onClick={() => {
                          if (history.location.pathname != `/questions/${question.question_id}`) {
                            history.push(`/questions/${question.question_id}`);
                            SideBar.instance()?.mounted();
                          }
                          this.search_value = '';
                          this.filterContent();
                        }}
                      >
                        Search for question
                      </Button.Success>
                    </Column>
                  </Row>
                ))}
            </div>
            <div style={{ position: 'absolute', bottom: '10px' }}>
              {/* Link to random question */}
              <Button.Light onClick={() => this.randomQuestion()}>
                I'm feeling lucky questions
              </Button.Light>{' '}
              {/* Link to random tag */}
              <Button.Light onClick={() => this.randomTag()}>
                I'm feeling lucky tags
              </Button.Light>{' '}
              {/* Link to random user */}
              <Button.Light onClick={() => this.randomUser()}>I'm feeling lucky users</Button.Light>
            </div>
          </div>
        )}
      </>
    );
  }

  randomTag() {
    // Selects one random tag from all.tags array and send user to the link of the tag
    const randomIndex = Math.floor(Math.random() * this.all.tags.length);
    if (history.location.pathname != `/tags/${this.all.tags[randomIndex].content}`) {
      history.push(`/tags/${this.all.tags[randomIndex].content}`);
      SideBar.instance()?.mounted();
    }
  }

  randomQuestion() {
    // Selects one random question from all.questions array and send user to the link of the question
    const randomIndex = Math.floor(Math.random() * this.all.questions.length);
    if (history.location.pathname != `/questions/${this.all.questions[randomIndex].question_id}`) {
      history.push(`/questions/${this.all.questions[randomIndex].question_id}`);
      SideBar.instance()?.mounted();
    }
  }

  randomUser() {
    // Selects one random user from all.users array and send user to the link of the user
    const randomIndex = Math.floor(Math.random() * this.all.users.length);
    if (history.location.pathname != `/profile/${this.all.users[randomIndex].username}`) {
      history.push(`/profile/${this.all.users[randomIndex].username}`);
      SideBar.instance()?.mounted();
    }
  }

  filterContent() {
    //Reset all arrays to improve efficiency, and to reset content
    this.filters.tags = [];
    this.filters.questions = [];
    this.filters.users = [];

    if (this.search_value[0] == '#') {
      //Filters all tags based on if the tag includes search_value (leading character is omitted)
      this.filters.tags = this.all.tags.filter((tag) =>
        tag.content.toLowerCase().includes(this.search_value.slice(1).toLowerCase()),
      );
    } else if (this.search_value[0] == '$') {
      //Filters all users based on if the user includes search_value (leading character is omitted)
      this.filters.users = this.all.users.filter((user) =>
        user.username.toLowerCase().includes(this.search_value.slice(1).toLowerCase()),
      );
    } else if (this.search_value != '') {
      //Filters all questions based on if the user includes search_value
      this.filters.questions = this.all.questions.filter((question) =>
        question.title.toLowerCase().includes(this.search_value.toLowerCase()),
      );
    }
  }

  async mounted() {
    try {
      if (await userService.authenticateUser()) this.user = await userService.getUser();
      this.user_loaded = true;
      this.all.tags = await tagServices.getAll();
      this.all.users = await userService.getAllUsers();
      this.all.questions = await questionServices.getEveryQuestion();
    } catch (error) {
      console.error(error);
    }
  }
}
