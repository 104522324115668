import * as React from 'react';
import { Component } from 'react-simplified';
import { upArrow, downArrow, Form, Row, Column, Button } from './widgets';
import questionServices from '../services/questionServices';
import { Question } from './customTypes';
import tagServices from '../services/tagServices';
import userService from '../services/userServices';
import { SideBar } from './barComponents';

import { history } from '../index';

export class Home extends Component {
  questions: Question[] = [];
  user_authenticated: boolean = false;

  tag: null | string = null;
  tag_favorited: boolean = false;

  // Sorting choices
  questionsType: string = 'recent';
  answered_select: string = 'both';

  render() {
    return (
      <div style={{ width: '90%', marginLeft: '5%' }}>
        <div style={{ marginTop: '5%' }}>
          {this.tag &&
            // If tag is defined i.e. the component is rendered on a /tags/_ page, show tag
            (this.user_authenticated ? (
              this.tag_favorited ? (
                <h1>
                  #{this.tag}{' '}
                  <Button.Light
                    onClick={() => {
                      this.addToFavoritesButton();
                    }}
                  >
                    Add To Favorites
                  </Button.Light>
                </h1>
              ) : (
                <h1>
                  #{this.tag}{' '}
                  <Button.Light
                    onClick={() => {
                      this.removeFromFavoritesButton();
                    }}
                  >
                    Remove From Favorites
                  </Button.Light>
                </h1>
              )
            ) : (
              <h1>#{this.tag} </h1>
            ))}
          <Row>
            <Column width={1}>
              {/* Sorting questions after popular */}
              <div
                className={`clean-hover ${this.questionsType === 'popular' ? 'active' : ''}`}
                onClick={() => {
                  this.questionsType = 'popular';
                  this.mounted();
                }}
              >
                Popular
              </div>
            </Column>
            <Column width={1}>
              {/* Sorting questions after recent */}
              <div
                className={`clean-hover ${this.questionsType === 'recent' ? 'active' : ''}`}
                onClick={() => {
                  this.questionsType = 'recent';
                  this.mounted();
                }}
              >
                Recent
              </div>
            </Column>
            <Column right>
              {/* Filter questions on answered */}
              <p style={{ margin: '4px 15px' }}>
                Filter by:{' '}
                <Form.Select
                  style={{ margin: '4px 15px' }}
                  value={this.answered_select}
                  onChange={(event) => {
                    this.answered_select = event.target.value;
                    this.mounted();
                  }}
                >
                  <option value={'both'}>Both</option>
                  <option value={'unanswered'}>Unanswered</option>
                  <option value={'answered'}>Answered</option>
                </Form.Select>
              </p>
            </Column>
          </Row>
        </div>
        <div style={{ marginTop: '1%' }}>
          {/* Map over all questions */}
          {this.questions.map((question) => (
            <div key={question.question_id}>
              <Row key={question.question_id}>
                <hr></hr>
                <Column width={1}>
                  {/* Column that contains upvote and downvote button */}
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      width: '50px',
                    }}
                  >
                    {' '}
                    <p
                      onClick={() => this.upvoteButton(question.question_id)}
                      className="nonSelectable vote-hover"
                      style={{ margin: '5px 0' }}
                    >
                      {question.voted_by_user == 1 ? upArrow('#209852') : upArrow('grey')}
                    </p>
                    <p
                      style={{
                        margin: '5px 0',
                        width: '100%',
                        textAlign: 'center',
                        userSelect: 'none',
                      }}
                    >
                      {' '}
                      {question.upvotes}
                    </p>
                    <p
                      onClick={() => this.downvoteButton(question.question_id)}
                      className="nonSelectable vote-hover"
                      style={{ margin: '5px 0' }}
                    >
                      {question.voted_by_user == 0 ? downArrow('#ed3e41') : downArrow('grey')}
                    </p>
                  </div>
                </Column>

                <Column matchParent width={11}>
                  {/* Column that contains rest of question info*/}
                  <div>
                    <span
                      // Link to profile page of author of question
                      className={question?.username == '[deleted]' ? '' : 'top-text nonSelectable'}
                      onClick={() => {
                        if (question?.username != '[deleted]')
                          history.push(`/profile/${question.username}`);
                      }}
                    >
                      {question.username}
                    </span>
                    <span className="top-text" style={{ marginLeft: '30px' }}>
                      {(() => {
                        // Returns formatted date of question
                        const date = new Date(question.timestamp);
                        let minutes = date.getMinutes().toString();
                        minutes = minutes.length < 2 ? '0' + minutes : minutes;
                        return `${date.getHours()}:${minutes} ${date.getDate()}.${date.getMonth()}.${date.getFullYear()}`;
                      })()}
                    </span>

                    <h5
                      // Title of quesion that links to question page
                      onClick={() => {
                        if (history.location.pathname != `/questions/${question.question_id}`)
                          history.push(`/questions/${question.question_id}`);
                      }}
                      className="question-hover"
                    >
                      <p style={{ wordWrap: 'break-word' }}>
                        <b>{question.title}</b>
                      </p>
                    </h5>
                    <p
                      // Force render of linebreaks
                      dangerouslySetInnerHTML={{ __html: question.content.replace(/\n/g, '<br>') }}
                      // CSS-class that limits content to 4 lines to avoid bloating
                      className="four-line-clamp"
                    ></p>
                  </div>
                  {question.tags?.map((tag, index) => (
                    // Map over the tags on a question
                    <span
                      key={index}
                      onClick={() => {
                        if (history.location.pathname != `/tags/${tag}`) {
                          // Tags link to the page of the tag
                          history.push(`/tags/${tag}`);
                          // Rerender sidebar to make it reflect current path
                          SideBar.instance()?.mounted();
                        }
                      }}
                      className="clean-hover-tag"
                    >
                      #{tag}{' '}
                    </span>
                  ))}
                </Column>
              </Row>
              <br></br>
            </div>
          ))}
        </div>
      </div>
    );
  }

  async addToFavoritesButton() {
    try {
      if (this.tag) await tagServices.addToFavorites({ content: this.tag });

      // Rerender sidebar to make it reflect updated content
      SideBar.instance()?.mounted();
      this.mounted();
    } catch (error) {
      console.error(error);
    }
  }

  async removeFromFavoritesButton() {
    try {
      if (this.tag) await tagServices.removeFromFavorites({ content: this.tag });

      // Rerender sidebar to make it reflect updated content
      SideBar.instance()?.mounted();
      this.mounted();
    } catch (error) {
      console.error(error);
    }
  }
  async upvoteButton(question_id: number) {
    try {
      await questionServices.upvote(question_id);
      this.mounted();
    } catch (error) {
      console.error(error);
    }
  }
  async downvoteButton(question_id: number) {
    try {
      await questionServices.downvote(question_id);
      this.mounted();
    } catch (error) {
      console.error(error);
    }
  }

  async mounted() {
    this.user_authenticated = await userService.authenticateUser();

    // Set correct value of tag based on path
    if (history.location.pathname === '/questions') {
      this.tag = null;
    } else if (history.location.pathname.startsWith('/tags/')) {
      const tag = history.location.pathname.split('/').pop();

      // Check if tag exists
      const tagExists = (await tagServices.getAll()).find((t) => t.content === tag) ? true : false;

      if (!tagExists) return console.error('404: Tag does not exist');

      if (tag) {
        this.tag = tag;
        if (this.user_authenticated) {
          const favorite_tags = await tagServices.getFavorites();
          this.tag_favorited = favorite_tags.find((tag) => tag.content == this.tag) ? false : true;
        }
      }
    }

    // Remains undefined if answer is both
    let answered;
    if (this.answered_select === 'answered') {
      answered = true;
    } else if (this.answered_select === 'unanswered') {
      answered = false;
    }

    try {
      // Call correct method based on if tag is defined
      if (this.tag) {
        this.questions = await questionServices.getQuestionsByTag(
          this.tag,
          this.questionsType,
          answered,
        );
      }
      if (!this.tag)
        this.questions = await questionServices.getAllQuestions(this.questionsType, answered);
    } catch (error) {
      console.error(error);
    }
  }
}
