import axios from 'axios';
import { User } from '../components/customTypes';

class UserService {
  // Gets all user info based on username
  async getUserByUsername(username: string) {
    try {
      const response = await axios.get<User>(`/users/${username}`);
      return response.data;
    } catch (error: unknown) {
      throw error;
    }
  }

  //Gets all content of user based on user_id
  async getContent(user_id: number) {
    try {
      const response = await axios.get('/users/get/' + user_id + '/content/all');
      const stats = response.data.stats;
      response.data.stats.xp =
        stats.answers * 100 + stats.questions * 50 + stats.comments * 30 + stats.points * 1;
      var title = '';

      //Define users title based on xp
      const xp = response.data.stats.xp;
      if (xp > 100000) {
        title += 'Ultimate ';
      }
      if (xp > 50000) {
        title += 'Giga ';
      }
      if (xp > 25000) {
        title += 'Mega ';
      }
      if (xp > 12000) {
        title += 'Huge ';
      }
      if (xp > 6000) {
        title += 'Nice ';
      }
      if (xp > 3000) {
        title += 'Cool ';
      }
      if (xp > 500) {
        title += 'Experienced ';
      }
      title += 'User';
      response.data.stats.title = title;
      return response.data;
    } catch (error: unknown) {
      throw error;
    }
  }

  // Tries to log user in
  async login(username: string, password: string) {
    try {
      const response = await axios.post('/login', { username, password });
      return response;
    } catch (error: unknown) {
      throw error;
    }
  }

  // Creates user
  async create(
    username: string,
    password: string,
    email: string,
    firstName: string,
    lastName: string,
  ) {
    try {
      const response = await axios.post('/users/create', {
        username,
        password,
        email,
        firstName,
        lastName,
      });
      return response;
    } catch (error: unknown) {
      throw error;
    }
  }

  // Gets own logged in user
  async getUser() {
    try {
      const response = await axios.get('/users/get/self');
      return response.data.user;
    } catch (error) {
      throw error;
    }
  }

  //Checks if user is authenticated
  async authenticateUser() {
    try {
      const response = await axios.get<{ authenticated: boolean }>('/users/authenticate/user');
      return response.data.authenticated;
    } catch (error) {
      throw error;
    }
  }

  // Log user out
  async logOut() {
    try {
      const response = await axios.get('/logout');
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Update user
  async updateUser(updatedUserData: User) {
    try {
      const response = await axios.put('/users/update', updatedUserData);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Deletes user
  async deleteUser(user_id: number) {
    try {
      const response = await axios.delete('/users/' + user_id + '/delete');
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Gets all users
  async getAllUsers() {
    try {
      const response = await axios.get<{ username: string }[]>('/users/search/all');
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

const userService = new UserService();

export default userService;
