import axios from 'axios';
import { Tag, TagWithAmount } from '../components/customTypes';

class TagServices {
  // Gets users favorites tags
  async getFavorites() {
    try {
      const favorites = await axios.get<Tag[]>('/tags/favorites');
      return favorites.data;
    } catch (error) {
      throw error;
    }
  }

  // Gets popular tags (popular is defined as tags with most questions)
  async getPopular() {
    try {
      const popular = await axios.get<Tag[]>('/tags/popular');
      return popular.data;
    } catch (error) {
      throw error;
    }
  }

  // Gets all tags
  async getAll() {
    try {
      const all = await axios.get<TagWithAmount[]>('/tags/all');
      return all.data;
    } catch (error) {
      throw error;
    }
  }

  // Adds tags to favorites
  async addToFavorites(tag: Tag) {
    try {
      const response = await axios.post('/tags/favorites', tag);
      return response;
    } catch (error) {
      throw error;
    }
  }

  // Remove tags from favorites
  async removeFromFavorites(tag: Tag) {
    try {
      const response = await axios.delete(`/tags/favorites/${tag.content}`);
      return response;
    } catch (error) {
      throw error;
    }
  }
}

const tagServices = new TagServices();
export default tagServices;
