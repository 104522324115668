import * as React from 'react';
import { Component } from 'react-simplified';
import { Card, Form, Button, Alert } from './widgets';
import { AxiosError } from 'axios';
import userService from '../services/userServices';
import { history } from '../index';

export class LoginPage extends Component {
  username: string = '';
  password: string = '';
  docker = false;

  render() {
    return (
      <Card id="loginCard">
        <h1>Login Page</h1>
        <b>Username</b>
        <Form.Input
          type=""
          value={this.username}
          onChange={(event) => (this.username = event.currentTarget.value)}
        ></Form.Input>
        <b>Password</b>
        <Form.Input
          type="Password"
          value={this.password}
          onChange={(event) => (this.password = event.currentTarget.value)}
          //Call loginButton() on "enter"
          onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
            if (event.key == 'Enter') this.loginButton();
          }}
        ></Form.Input>
        <br />
        <Button.Success onClick={() => this.loginButton()}>Login</Button.Success>
        <br />
        <br></br>
        {!this.docker && (
          <a className="google-login" href="/auth/google">
            Sign in with Google
          </a>
        )}
        <br />
        Not registered?
        <br />
        <u
          style={{ color: 'blue' }}
          onClick={() => history.push('/createUser')}
          className="nonSelectable"
        >
          {' '}
          Register here{' '}
        </u>
        <br></br>
      </Card>
    );
  }
  async loginButton() {
    try {
      await userService.login(this.username, this.password);
      //Push to main page if succesfull
      history.push('/questions');
    } catch (error) {
      if (error instanceof AxiosError && error.response)
        Alert.danger(`Error logging in: ${error.response.data.message}`);
      else Alert.danger(`Error logging in: Unkown error`);
    }
  }
  mounted() {
    if (process.env.NODE_ENV != 'test') {
      //@ts-ignore
      if (DOCKER) {
        this.docker = true;
      }
    }
  }
}
