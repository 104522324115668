import * as React from 'react';
import { Component } from 'react-simplified';
import { Card, Row, Column, Form } from './widgets';
import tagServices from '../services/tagServices';
import { TagWithAmount } from './customTypes';
import { history } from '../index';
import { SideBar } from './barComponents';
import { Question } from './customTypes';

export class Tags extends Component {
  tags: TagWithAmount[] | null = null;
  filteredTags: TagWithAmount[] = [];

  hoveredCard = -1;
  isLoading = true;
  sortMethod = 'htl';

  questions: Question[] = [];
  tag_search_input: string = '';

  render() {
    if (!this.tags) return;
    return (
      <div>
        <Card title="">
          <h1>All Tags</h1>
          <Row>
            <Column width={2}>
              <Form.Select
                value={this.sortMethod}
                //Calls sortTags() to update sorting based on selection
                onChange={(event) => {
                  this.sortMethod = event.target.value;
                  this.sortTags();
                }}
              >
                <option value="alphabetical">Alphabetical</option>
                <option value="lth">Low to high</option>
                <option value="htl">High to low</option>
              </Form.Select>
            </Column>
            <Column>
              <Form.Input
                placeholder="Search for tags"
                value={this.tag_search_input}
                //Dynamically sorts tags based on input
                onChange={(event) => {
                  this.tag_search_input = event.currentTarget.value
                    .toLowerCase()
                    .replace(' ', '-')
                    .replace(/[^a-zA-Z0-9-]/g, '');
                  this.sortTags();
                }}
                type="string"
              ></Form.Input>
            </Column>
          </Row>
          <br></br>
          <Row>
            {this.filteredTags.map((tag, index) => (
              <Column key={index} width={3}>
                <div
                  //push to the page of the tag onClick
                  onClick={() => {
                    history.push(`/tags/${tag.content}`);
                    SideBar.instance()?.mounted();
                  }}
                  //Set hovereffect
                  onMouseEnter={() => (this.hoveredCard = index)}
                  onMouseLeave={() => (this.hoveredCard = -1)}
                  className="nonSelectable"
                >
                  <Card
                    title={<b>{tag.content}</b>}
                    style={{
                      marginTop: '2%',
                      marginBottom: '2%',
                      width: '18vw',
                      height: '14vw',
                      transition: '0.2s',
                      overflow: 'auto',
                      boxShadow:
                        this.hoveredCard === index
                          ? '0 8px 16px 0 rgba(50, 180, 249, 0.5)'
                          : 'none', // Apply boxShadow if card is hovered
                      transform: this.hoveredCard === index ? 'scale(1.05)' : 'scale(1)', // Scale card if it's hovered
                    }}
                  >
                    <span>Amount of posts using this tag: {tag.question_amount}</span>
                  </Card>
                </div>
              </Column>
            ))}
          </Row>
        </Card>
      </div>
    );
  }

  sortTags() {
    if (!this.tags) return;
    //Filter tags based on input
    this.filteredTags = this.tags.filter((tag) =>
      tag.content.toLowerCase().includes(this.tag_search_input.toLowerCase()),
    );
    //Sort tags based on selected method
    if (this.sortMethod === 'alphabetical') {
      this.filteredTags.sort((a, b) => a.content.localeCompare(b.content));
    } else if (this.sortMethod === 'lth') {
      this.filteredTags.sort((a, b) => a.question_amount - b.question_amount);
    } else if (this.sortMethod === 'htl') {
      this.filteredTags.sort((a, b) => b.question_amount - a.question_amount);
    }
  }

  async mounted() {
    try {
      this.tags = await tagServices.getAll();
      this.sortTags();
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  }
}
