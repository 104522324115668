import * as React from 'react';
import { Component } from 'react-simplified';
import { Card, Form, Row, Column, Button, Alert } from './widgets';
import userService from '../services/userServices';
import { AxiosError } from 'axios';
import { User, Question, contentType, Answer, Q_comment, A_comment } from './customTypes';

import { history } from '../index';
import questionServices from '../services/questionServices';

export class Profile extends Component<{ match: { params: { username: string } } }> {
  loggedInUser: User | null = null;
  user: User | null = null;
  //this.loggedInUser refers to the user that is logged in
  //this.user refers to the user of the profilepage

  content: contentType = {
    questions: [],
    answers: [],
    q_comments: [],
    a_comments: [],
    stats: { questions: 0, answers: 0, comments: 0, points: 0, xp: 0, title: '' },
  };

  newUsername = '';
  newEmail = '';
  newAboutMe = '';
  newPFP = '';
  activeButton: string = 'profile'; // Initialize activeButton with a default value
  savedQuestions: Question[] = [];

  deleteUserToggle: boolean = false;

  render() {
    if (this.user && this.loggedInUser) {
      return (
        <div style={{ marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}>
          <Row>
            {/* Row loads inn basic user info */}
            <Column width={3}>
              <img
                src={this.user.picture}
                style={{ width: '250px', height: '250px' }}
                alt={`${this.user.username}'s profile picture`}
              ></img>
            </Column>
            <Column>
              <h1>{`${this.user.username}'s profile`}</h1>
              {this.user.fullname ? <h5>{this.user.fullname}</h5> : ''}
              <h6>{this.user.email}</h6>
              {this.user.about_me && this.user.about_me.length > 0 && (
                <Row>
                  <Column>
                    <hr></hr>
                    <p style={{ wordBreak: 'break-all' }}>
                      <i>"{this.user.about_me}"</i>
                    </p>{' '}
                  </Column>
                </Row>
              )}
            </Column>
          </Row>
          <hr></hr>

          {this.user.username == this.loggedInUser.username && (
            //Loads buttons if user is viewing own page
            <Row>
              <Column>
                <span
                  className={`clean-hover-boxy ${this.activeButton === 'profile' ? 'active' : ''}`}
                  onClick={() => (this.activeButton = 'profile')}
                >
                  <b>Profile</b>
                </span>{' '}
                <span
                  className={`clean-hover-boxy ${this.activeButton === 'saved' ? 'active' : ''}`}
                  onClick={() => (this.activeButton = 'saved')}
                >
                  <b>Saved Questions</b>
                </span>{' '}
                <span
                  className={`clean-hover-boxy ${this.activeButton === 'settings' ? 'active' : ''}`}
                  onClick={() => (this.activeButton = 'settings')}
                >
                  <b>Settings</b>
                </span>{' '}
                <span className="button-danger" onClick={() => this.logOutButton()}>
                  <b>Log Out</b>
                </span>
              </Column>
            </Row>
          )}
          <hr></hr>
          {this.activeButton === 'profile' && (
            //shows alle questions and interactions a user has had
            //since this is default value, it is shown to everyone
            <>
              <Card title={''} style={{ width: '100%' }}>
                <h2>
                  <b>{this.user.username}'s Stats</b>
                </h2>
                <Row>
                  <hr></hr>
                </Row>
                <Row>
                  <Column width={2}>Answers: {this.content.stats.answers}</Column>
                  <Column width={2}>Questions: {this.content.stats.questions}</Column>
                  <Column width={2}>Comments: {this.content.stats.comments}</Column>
                </Row>
                <Row>
                  <Column width={2}>XP: {this.content.stats.xp}</Column>
                  <Column width={2}>Points: {this.content.stats.points}</Column>
                  <Column width={2}>Title: {this.content.stats.title}</Column>
                </Row>
              </Card>

              {this.content.questions.length > 0 && (
                <Card title={''} style={{ width: '100%' }}>
                  <h2>
                    <b>Questions</b>
                  </h2>
                  {this.content.questions.map((question) => (
                    <div key={question.question_id}>
                      <hr></hr>
                      <p
                        className="nonSelectable"
                        onClick={() => {
                          history.push(`/questions/${question.question_id}`);
                        }}
                      >
                        <i className="custom-text-size">{question.title}</i> <br></br>
                        <span>
                          {new Date(question.timestamp).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })}
                        </span>
                      </p>
                    </div>
                  ))}
                </Card>
              )}
              {(this.content.answers.length > 0 || this.content.answers.length > 0) && (
                <Card title={''} style={{ width: '100%' }}>
                  <h2>
                    <b>Interactions</b>
                  </h2>
                  {this.content.answers.map((answer) => (
                    <div key={answer.answer_id}>
                      <hr></hr>
                      <p
                        className="nonSelectable"
                        onClick={() => {
                          history.push(`/questions/${answer.question_id}`);
                        }}
                      >
                        <i
                          //CSS-class that limits content to 4 lines to avoid bloating
                          className="custom-text-size four-line-clamp"
                          //Force render of linebreaks
                          dangerouslySetInnerHTML={{
                            __html: `"${answer.content.replace(/\n/g, '<br>')}"`,
                          }}
                        />
                        <br></br>
                        <b>Answer to question -</b> {answer.title}
                        <span>
                          {' '}
                          {new Date(answer.timestamp).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })}
                        </span>
                      </p>
                    </div>
                  ))}
                  {this.content.q_comments.map((q_comment) => (
                    <div key={q_comment.comment_id}>
                      <hr></hr>
                      <p
                        className="nonSelectable"
                        onClick={() => {
                          history.push(`/questions/${q_comment.question_id}`);
                        }}
                      >
                        <i
                          //CSS-class that limits content to 4 lines to avoid bloating
                          className="custom-text-size four-line-clamp"
                          //Force render of linebreaks
                          dangerouslySetInnerHTML={{
                            __html: `"${q_comment.content.replace(/\n/g, '<br>')}"`,
                          }}
                        />
                        <br></br>
                        <b>Comment to question - </b> {q_comment.title}
                        <span>
                          {' '}
                          {new Date(q_comment.timestamp).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })}
                        </span>
                      </p>
                    </div>
                  ))}
                  {this.content.a_comments.map((a_comment) => (
                    <div key={a_comment.comment_id}>
                      <hr></hr>
                      <p
                        className="nonSelectable"
                        onClick={() => {
                          history.push(`/questions/${a_comment.question_id}`);
                        }}
                      >
                        <i
                          //CSS-class that limits content to 4 lines to avoid bloating
                          className="custom-text-size four-line-clamp"
                          //Force render of linebreaks
                          dangerouslySetInnerHTML={{
                            __html: `"${a_comment.content.replace(/\n/g, '<br>')}"`,
                          }}
                        />
                        <br></br>
                        <b>Comment to answer in question - </b> {a_comment.title}{' '}
                        <span>
                          {new Date(a_comment.timestamp).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })}
                        </span>
                      </p>
                    </div>
                  ))}
                </Card>
              )}
            </>
          )}
          {this.activeButton === 'settings' && (
            <>
              <Row>
                <Column>
                  {/* Edit user info content */}
                  <Column>
                    {<h4>Edit userinfo</h4>}
                    <Card title="" style={{ width: '26vw', height: '38%' }}>
                      <Row>
                        <Column>
                          <b>Username</b>
                          <Form.Input
                            type=""
                            value={this.newUsername}
                            onChange={(event) => {
                              //regex to sanitize input
                              this.newUsername = event.currentTarget.value
                                .replace(/[^a-zA-Z0-9-_]/g, '')
                                .replace(/\u200B/g, '');
                            }}
                          ></Form.Input>
                          <b>Email</b>
                          <Form.Input
                            type=""
                            value={this.newEmail}
                            onChange={(event) => {
                              //regex to sanitize input
                              this.newEmail = event.currentTarget.value
                                .replace(/[^a-zA-Z0-9!#$%&*+\-_~@.]+/g, '')
                                .replace(/\u200B/g, '');
                            }}
                          ></Form.Input>
                          <b>Profile picture (URL)</b>
                          <Form.Input
                            type=""
                            value={this.newPFP}
                            onChange={(event) => {
                              this.newPFP = event.currentTarget.value;
                            }}
                          ></Form.Input>
                        </Column>
                      </Row>
                    </Card>
                  </Column>
                </Column>
                <Column width={8}>
                  {/* Edit about me content */}
                  <h4>Edit about me</h4>
                  <Form.Textarea
                    value={this.newAboutMe}
                    onChange={(event) => (this.newAboutMe = event.currentTarget.value)}
                    rows={10} // Increase the number of rows for height
                    cols={80}
                    // style={{ width: '1000%', height: '200px' }} // Adjust the width and height
                  ></Form.Textarea>
                  <div style={{ textAlign: 'right' }}>{this.newAboutMe.length} / 500</div>
                </Column>
              </Row>
              <br></br>
              <Row>
                <Column>
                  {' '}
                  <Button.Success onClick={() => this.user && this.saveButton(this.user)}>
                    Save all changed data
                  </Button.Success>
                </Column>
                <Column right>
                  {this.deleteUserToggle ? (
                    <>
                      <span>Are you sure? </span>
                      <Button.Light onClick={() => (this.deleteUserToggle = false)}>
                        No
                      </Button.Light>{' '}
                      <Button.Danger onClick={() => this.deleteUserButton()}>Yes</Button.Danger>
                    </>
                  ) : (
                    <Button.Danger onClick={() => (this.deleteUserToggle = true)}>
                      Delete User
                    </Button.Danger>
                  )}
                </Column>
              </Row>
              <br></br>
            </>
          )}

          {this.activeButton === 'saved' && (
            //show own saved questions
            <Card title="">
              <h2>
                <b>Saved questions</b>
              </h2>
              {this.savedQuestions.map((question) => (
                <div key={question.question_id}>
                  <hr></hr>
                  <p
                    className="question-hover custom-text-size"
                    onClick={() => history.push(`/questions/${question.question_id}`)}
                  >
                    <b>{question.title}</b>
                  </p>
                  <span>
                    {new Date(question.timestamp).toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                    })}
                  </span>
                </div>
              ))}
            </Card>
          )}
        </div>
      );
    } else {
      return;
    }
  }

  async logOutButton() {
    try {
      await userService.logOut();
      history.push('/questions');
    } catch (error) {
      console.error(error);
    }
  }

  async saveButton(user: User) {
    try {
      //check if user input is allowed
      if (this.newUsername != '' && this.newUsername.length >= 2 && this.newUsername.length <= 32) {
        user.username = this.newUsername;
      } else {
        throw 'Username format wrong';
      }
      if (this.newEmail != '') {
        user.email = this.newEmail;
      } else {
        throw 'Email format wrong';
      }
      if (this.newAboutMe.length > 500) {
        throw 'About Me section cannot exceed 500 characters';
      }
      user.about_me = this.newAboutMe;

      //make sure image is a valid url
      const validImageExtensions = ['.jpg', '.jpeg', '.png', '.gif'];
      const isImageUrlValid = validImageExtensions.some((ext) => this.newPFP.endsWith(ext));

      if (this.newPFP != '' && isImageUrlValid) {
        user.picture = this.newPFP;
      } else if (this.newPFP != '' && !isImageUrlValid) {
        throw 'Invalid image URL';
      } else {
        user.picture = 'https://livileire.no/wp-content/uploads/2022/03/Portrait_Placeholder.jpg';
      }

      await userService.updateUser(user);
      this.activeButton = 'profile';
      Alert.success('User data updated');
      history.push('/profile/' + user.username);
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response) {
        Alert.danger(`Error updating user data: ${error.response.data.message}`);
      } else {
        Alert.danger(`Error updating user data: ${error}`);
      }
    }
  }

  async deleteUserButton() {
    try {
      if (this.loggedInUser) await userService.deleteUser(this.loggedInUser.user_id);
      history.push('/questions');
    } catch (error) {
      //alert
      console.error(error);
    }
  }

  sort() {
    // Reusable sorting function that sorts dates in descending order
    const sortByTimestampDesc = (
      a: Question | Answer | Q_comment | A_comment,
      b: Question | Answer | Q_comment | A_comment,
    ) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime();

    // Sort questions, answers, q_comments, and a_comments
    this.content.questions.sort(sortByTimestampDesc);
    this.content.answers.sort(sortByTimestampDesc);
    this.content.q_comments.sort(sortByTimestampDesc);
    this.content.a_comments.sort(sortByTimestampDesc);
  }

  async mounted() {
    if (history.location.pathname == '/profile/[deleted]') history.goBack();
    // Returnes user to previous path if trying to reach /profile/[deleted]

    try {
      this.user = await userService.getUserByUsername(this.props.match.params.username);
      if (await userService.authenticateUser()) {
        this.loggedInUser = await userService.getUser();
        if (this.loggedInUser?.username === this.user.username)
          this.savedQuestions = await questionServices.getSavedQuestions();
      } else this.loggedInUser = { user_id: 0, username: '', email: '', about_me: '', picture: '' };
      this.content = await userService.getContent(this.user.user_id);

      //sort content
      this.sort();

      if (this.user) {
        this.newUsername = this.user.username;
        this.newEmail = this.user.email;
        this.newAboutMe = this.user.about_me || '';
        this.newPFP = this.user.picture;
      }
    } catch (error) {
      console.error(error);
    }
  }
}
