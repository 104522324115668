import axios from 'axios';
import { Tag, Question, Q_comment, Answer } from '../components/customTypes';

class QuestionServices {
  // Get one question
  async getQuestion(question_id: number) {
    try {
      const response = await axios.get<Question>('/questions/' + question_id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Get every question ever posted
  async getEveryQuestion() {
    try {
      const response = await axios.get<Question[]>('/questions/every');
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Get questions sorted on answered and recent/popular, returns 10 questions
  async getAllQuestions(sorting: string, answered?: boolean) {
    var query: string = '/questions/all/' + sorting;
    if (answered == true) {
      query += '/answered';
    } else if (answered == false) {
      query += '/unanswered';
    } else if (answered == undefined) {
      query += '/scrambled';
    }
    try {
      const response = await axios.get<Question[]>(query);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Gets all question with a tag, sorted by popular/recent and filtered on if it is answered
  async getQuestionsByTag(tag: string, sorting?: string, answered?: boolean) {
    var query: string = '/questions/bytag/' + tag + '/' + sorting;
    if (answered == true) {
      query += '/answered';
    } else if (answered == false) {
      query += '/unanswered';
    } else if (answered == undefined) {
      query += '/scrambled';
    }
    try {
      const response = await axios.get<Question[]>(query);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Creates question
  async createQuestion(title: string, content: string, tags: Tag[]) {
    try {
      const response = await axios.post<{ question_id: number }>('/questions/new/question', {
        title: title,
        content: content,
        tags: tags,
      });
      return response.data.question_id;
    } catch (error) {
      throw error;
    }
  }

  // Deletes question
  async deleteQuestion(question_id: number) {
    try {
      const response = await axios.delete<Question>('/questions/delete/question/' + question_id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Updates question
  async update(question: Question) {
    try {
      const response = await axios.put<Question>('/questions/update/question', question);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Upvote question
  async upvote(question_id: number) {
    try {
      const response = await axios.put('/questions/upvote/' + question_id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Downvote question
  async downvote(question_id: number) {
    try {
      const response = await axios.put('/questions/downvote/' + question_id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Add new comment to question
  async newComment(question_id: number, content: string) {
    try {
      const response = await axios.post('/questions/new/comment', { question_id, content });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Edit comment on question
  async editComment(comment: Q_comment) {
    try {
      const response = await axios.put('/questions/update/comments', { comment });
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Delete comment on question
  async deleteComment(comment: Q_comment) {
    try {
      const response = await axios.delete('/questions/delete/comment/' + comment.comment_id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Select best answer on question
  async selectBestAnswer(answer: Answer) {
    try {
      const response = await axios.put('/questions/update/answers/best', answer);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Unsselect best answer on question
  async unselectBestAnswer(answer: Answer) {
    try {
      const response = await axios.put('/questions/update/answers/best/unselect', answer);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Saves question to a user
  async saveQuestion(question: Question) {
    try {
      const response = await axios.post('/questions/save', question);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Unsaves question to a user
  async unsaveQuestion(question: Question) {
    try {
      const response = await axios.delete('/questions/unsave/' + question.question_id);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  //Gets a users favorite questions
  async getSavedQuestions() {
    try {
      const response = await axios.get('/questions/saved');
      return response.data;
    } catch (error) {
      throw error;
    }
  }
}

const questionServices = new QuestionServices();
export default questionServices;
